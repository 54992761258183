<template>
  <div class="multi-places-list">
    <!-- ToolBar -->
    <VuetifyToolBar
      title="Gestionar establecimientos"
      :hide-back-button="true"
      :hide-action-button="false"
    >
      <!-- Popover Menu -->
      <template v-slot:actionButton>
        <VuetifyPopoverMenu
          icon="mdi-dots-vertical"
          :items="currentPopoverMenuItems"
          @onClickMenuItem="handleClickMenuItem"
        />
      </template>
    </VuetifyToolBar>
    <v-container class="d-flex flex-column grow">
      <!-- Loading -->
      <VuetifyContentLoading :loading="processingRequest" />
      <!-- Establecimientos actuales -->
      <CardContainer
        v-if="!processingRequest"
        padding-content="sm-p"
        class="mb-4"
        title="Asociados"
      >
        <template v-if="placeListDoneData.length">
          <VuetifyList :items="placeListDoneData">
            <template v-slot="{ item }">
              <MultiPlacesListItem :item="item" @onClickItem="handleClickPlace" />
            </template>
          </VuetifyList>
        </template>
      </CardContainer>
      <!-- Establecimientos pendientes -->
      <CardContainer
        v-if="!processingRequest && placeListPendingData.length"
        padding-content="sm-p"
        title="Pendientes de fusión"
      >
        <VuetifyList :items="placeListPendingData">
          <template v-slot="{ item }">
            <MultiPlacesListItem
              :item="item"
              @onSendEmail="handleSendEmail"
              @onRemovePlace="handleRemovePlace"
            />
          </template>
        </VuetifyList>
      </CardContainer>
    </v-container>
  </div>
</template>

<script>
// Constants
import { URL_APP_HORECA, ADDONS } from '@/constants'
// Components
import MultiPlacesListItem from '../components/elements/MultiPlacesListItem'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import CardContainer from '@/components/ui/CardContainer'
import VuetifyList from '@/components/ui/VuetifyList'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import VuetifyPopoverMenu from '@/components/ui/VuetifyPopoverMenu'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'
// Services
import { getEveryPlacesByIds } from '@/services/place'
import {
  getEveryFusionPlacesPendingByUserId,
  deleteFusionPlaceById
} from '../services/fusionPlaces'
import { sendAnySendinblueEmail } from '@/services/email'
// Utils
import { get } from 'lodash'
import { getEnvironmentVariable } from '@/utils'

export default {
  name: 'MultiPlacesList',
  components: {
    MultiPlacesListItem,
    VuetifyContentLoading,
    VuetifyList,
    VuetifyPopoverMenu,
    VuetifyToolBar,
    CardContainer
  },
  mixins: [addonsMixin, uiMixin],
  data() {
    return {
      // places data
      placeListDoneData: [],
      placeListPendingData: [],
      // PopoverMenu Items"
      popoverMenuItems: [
        {
          label: 'Nuevo establecimiento',
          route: 'multiPlacesCreate'
        },
        {
          label: 'Clonar establecimiento',
          route: 'multiPlacesClone'
        },
        {
          label: 'Fusionar cuentas',
          route: 'multiPlacesFusion'
        }
      ],
      // others
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData']),
    ...mapGetters('company', ['companyData']),
    /**
     * Estamos bajo la aplicación de Schweppes
     */
    isSchweppes() {
      return getEnvironmentVariable('VUE_APP_BRAND') === 'schweppes'
    },
    /**
     * Items del menú desplegable
     *
     * @return {Array}
     */
    currentPopoverMenuItems() {
      return this.isSchweppes
        ? this.popoverMenuItems.filter((item) => {
            return item.route !== 'multiPlacesFusion'
          })
        : this.popoverMenuItems
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    ...mapMutations('place', ['setPlace', 'resetPlace']),
    ...mapMutations('app', ['setSelectedPlace']),
    ...mapActions('authentication', ['updateUser']),
    /**
     * Acción lanzada tras pulsar en alguna de las
     * opciones del "Popover menu"
     *
     * @param {Number} index - selected option
     */
    handleClickMenuItem(index) {
      // Se obtiene el numero limite de places
      const numPlaces = get(this.companyAddonsSetupByUser, `${ADDONS.multiPlaces}.numPlaces`, false)

      if (!numPlaces) {
        this.handleError(
          'Existen errores en tu configuración, póngase en contacto con la empresa que administra el servicio'
        )
      } else if (Object.keys(this.companyData.places).length >= numPlaces) {
        this.handleError('Has alcanzado el límite máximo de establecimientos permitidos')
      } else {
        this.routerPushTo({ name: this.popoverMenuItems[index].route })
      }
    },
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Se setea el places seleccionado en el store y se redirigue haci­a la home
     *
     * @param {object} place - Datos del establecimiento
     */
    handleClickPlace(place) {
      // Vaciamos los datos del establecimiento del "state"
      // y establecemos los nuevos
      this.resetPlace()
      this.setPlace(place)
      // Establecemos como seleccionado dicho establecimiento
      this.setSelectedPlace(place.id)
      // Redirigimos a la HOME
      this.routerPushTo({ name: 'dashboard' })
    },
    /**
     * Se vuelve a enviar el email de confirmación de fusión
     *
     * @param {Object} itemList - item de lista
     */
    async handleSendEmail(itemList) {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          try {
            itemList.loadingSend = true
            // Re-enviamos el email
            await sendAnySendinblueEmail({
              method: 'sendEmailFusionPlacesValidate',
              email: itemList.emailTarget,
              customParams: {
                name: itemList.name,
                endpoint: `${URL_APP_HORECA}multi-places-endpoint/${itemList.fusionPlaceKey}`
              }
            })
            // Se le muestra al usuario que todo ha salido correctamente
            this.modifyAppAlert({
              text: 'El email fue enviado correctamente, compruebe su correo.',
              visible: true
            })
          } catch (error) {
            this.handleError(error.message)
          } finally {
            itemList.loadingSend = false
          }
        },
        actionButtonText: 'Enviar',
        text: '¿Desea re-enviar de nuevo el email?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Se elimina una fusión pendiente de confirmación
     *
     * @param {Object} placeData - item de lista
     */
    async handleRemovePlace(itemList) {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          try {
            // Loading ...
            itemList.loadingDelete = true
            // Se elimina la fusión pendiente
            const { ok } = await deleteFusionPlaceById(itemList.fusionPlaceKey)

            if (!ok) {
              throw new Error('Hubo un error al intentar eliminar el intento de fusión.')
            }

            // Se actualizan las listas
            this.placeListDoneData = await this.getPlaceListDoneData()
            this.placeListPendingData = await this.getPlaceListPendingData()
            // Se le muestra al usuario que todo ha salido correctamente
            this.modifyAppAlert({
              text: 'Proceso de intento de fusión, eliminado correctamente',
              visible: true
            })
          } catch (error) {
            this.handleError(error.message)
          } finally {
            itemList.loadingDelete = false
          }
        },
        actionButtonText: 'Eliminar',
        text: '¿Desea eliminar el proceso de fusión?',
        type: 'warning',
        visible: true
      })
    },
    /**
     * Obtiene todos los datos necesarios para la vista
     */
    async getEveryNeededData() {
      try {
        this.processingRequest = true
        // Setea la lista de places activos
        this.placeListDoneData = await this.getPlaceListDoneData()
        // Setea la lista de places pendientes
        this.placeListPendingData = await this.getPlaceListPendingData()
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    },
    /**
     *  Se obtienen todos los Places
     *
     * @return {Array}
     */
    async getPlaceListDoneData() {
      // Se obtiene todos los places asociados al usuario autenticado
      const placesKeys = Object.keys(this.companyData.places)

      return (
        await getEveryPlacesByIds(placesKeys, {
          includeAddOnsConfigs: true,
          includeSubscriptions: true
        })
      ).map((place) => {
        const addOnConfigs = get(place, 'addOnConfigs', [])
        const configData = addOnConfigs.find((config) => config.id === ADDONS.place)
        return {
          ...place,
          name: configData.configFields.name || place.name
        }
      })
    },
    /**
     *  Se obtiene lista de fusionPlaces y place
     *
     * @return {Array}
     */
    async getPlaceListPendingData() {
      // Se obtiene la lista de fusiones pendientes asociados al usuario
      const fusionPlacesPending = await getEveryFusionPlacesPendingByUserId(this.userData.id)
      // Se obtienen un array con los place Keys
      const fusionPlacesPendingKeys = fusionPlacesPending.map(
        (fusionPlace) => fusionPlace.placeKeyTarget
      )
      // Se busca todos los places de la lista de keys
      const placeListPending = (await getEveryPlacesByIds(fusionPlacesPendingKeys)).map((place) => {
        const addOnConfigs = get(place, 'addOnConfigs', [])
        const configData = addOnConfigs.find((config) => config.id === ADDONS.place)
        return {
          ...place,
          name: configData.configFields.name || place.name
        }
      })

      // Se formatea con clave valor para reutilizarla mas facilmente
      const fusionPlacesByPlaceKey = fusionPlacesPending.reduce((acc, el) => {
        acc[el.placeKeyTarget] = el
        return acc
      }, {})

      // Se crea el array que utilizará la lista
      return placeListPending.map((place) => {
        return {
          placeKey: place.id,
          name: place.name,
          fusionPlaceKey: fusionPlacesByPlaceKey[place.id].id,
          emailTarget: fusionPlacesByPlaceKey[place.id].emailTarget,
          createTimestamp: fusionPlacesByPlaceKey[place.id].createTimestamp,
          status: fusionPlacesByPlaceKey[place.id].status,
          loadingSend: false,
          loadingDelete: false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.multi-places-list {
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
</style>
