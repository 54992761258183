<template>
  <v-list-item class="multiplaces-list-item">
    <v-list-item-content>
      <v-list-item-subtitle class="primary--text">
        {{ item.name }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <template v-if="!itemHasFusionActions">
        <v-btn fab small icon @click="handleClickItem(item)">
          <v-icon>mdi-login</v-icon>
        </v-btn>
      </template>
      <template v-else>
        <!-- Button Send Email -->
        <v-btn
          fab
          small
          icon
          :loading="item.loadingSend"
          :disabled="item.loadingSend"
          @click="handleSendEmail(item)"
          ><v-icon>mdi-email-send-outline</v-icon>
        </v-btn>
        <!-- Buttons Delete  -->
        <v-btn
          fab
          small
          icon
          :loading="item.loadingDelete"
          :disabled="item.loadingDelete"
          @click="handleRemovePlace(item)"
          ><v-icon color="red">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
// Contants
import { FUSION_PLACES_STATUS } from '../../constants'

export default {
  name: 'MultiPlacesListItem',
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    /**
     * Mostramos las opciones (botones) de fusión
     * de un establecimiento (proceso)
     */
    itemHasFusionActions() {
      return this.item.status === FUSION_PLACES_STATUS.pending
    }
  },
  methods: {
    /**
     * Evento de seleccion de item
     * @param {Object} item
     */
    handleClickItem(item) {
      this.$emit('onClickItem', item)
    },
    /**
     * Evento de enviar email
     * @param {Object} item
     */
    handleSendEmail(item) {
      this.$emit('onSendEmail', item)
    },
    /**
     * Evento de eliminar item
     * @param {Object} item
     */
    handleRemovePlace(item) {
      this.$emit('onRemovePlace', item)
    }
  }
}
</script>
<style lang="scss" scoped>
.multiplaces-list-item {
  background-color: #fff;
  margin-bottom: 1px solid $theme_border_color;
  .v-list-item__action {
    flex-direction: row;
    .v-btn:last-child {
      margin-left: 4px;
    }
  }
}
</style>
